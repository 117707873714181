import React from 'react'

const Partnership = () => {
  return (
    <div>
      <h1>Partnership Page Under Construction</h1>
      <p>Harap bersabar InsyaAllah dalam proses pengembangan</p>
    </div>
  )
}

export default Partnership