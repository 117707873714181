import MyNavbar from "../main/Navbar"

const ProductHeader = () => {
  return (
    <>
      <MyNavbar />
      <div>ProductHeader</div>
    </>
  )
}

export default ProductHeader