import React from 'react'

const AboutUs = () => {
  return (
    <div>
      <h1>About Us Page Under Construction</h1>
      <p>Harap bersabar InsyaAllah dalam proses konstruksi</p>
    </div>
  )
}

export default AboutUs